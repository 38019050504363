import React, {useEffect, useMemo, useRef} from 'react';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import './Checkbox.scss';

export enum ComponentSize {
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
}

export enum ComponentColor {
    PRIMARY = 'primary',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    DANGER = 'danger',
}

export interface CheckboxProps {
    id?: string;
    size?: ComponentSize;
    solid?: boolean;
    asSwitch?: boolean;
    loading?: boolean;
    withLoader?: boolean;
    color?: ComponentColor;
    disabled?: boolean;
    indeterminate?: boolean;
    checked?: boolean;
    className?: string;
    inputClassName?: string;
    onChange?: (checked: boolean) => void;
    children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
    id,
    size = ComponentSize.MEDIUM,
    solid = true,
    asSwitch = false,
    loading,
    withLoader = false,
    color = ComponentColor.PRIMARY,
    disabled,
    indeterminate,
    checked,
    className,
    inputClassName,
    onChange = () => {},
    children,
}) => {
    id = useMemo(() => id || `checkbox-${uniqueId()}`, [id]);
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (input.current) {
            input.current.indeterminate = !!indeterminate && !checked;
        }
    }, [indeterminate, checked]);

    const classList = [
        solid && 'form-check-solid',
        asSwitch && 'form-switch',
        ['sm', 'lg'].includes(size) && `form-check-${size}`,
        Object.values(ComponentColor)
            .filter((color) => color !== ComponentColor.PRIMARY)
            .includes(color) && `form-check-${color}`,
        className,
    ];

    return (
        <div
            className={clsx(
                'form-check form-check-custom',
                withLoader && asSwitch && 'form-switch-loader',
                loading && 'load',
                classList
            )}
        >
            <input
                ref={input}
                checked={checked}
                className={clsx('form-check-input', inputClassName)}
                type="checkbox"
                value=""
                id={id}
                disabled={disabled}
                onChange={({target}) => {
                    const {checked} = target;
                    onChange(checked);
                }}
            />
            {withLoader && asSwitch && <div onClick={() => onChange(!checked)}></div>}
            {children && (
                <label className="form-check-label" htmlFor={id}>
                    {children}
                </label>
            )}
        </div>
    );
};

export {Checkbox};
