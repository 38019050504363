import React, {ComponentClass, FunctionComponent} from "react";
import {Field, fieldPropKeys, FieldProps} from "../Field";
import {Checkbox, CheckboxProps} from "../Checkbox";
import omit from "lodash/omit";
import pick from "lodash/pick";
import uniqueId from "lodash/uniqueId";

export interface CheckboxFieldProps {
    component?: FunctionComponent | ComponentClass | any
}

const CheckboxField: React.FC<CheckboxFieldProps & CheckboxProps & FieldProps> = (props) => {
    const fieldProps = pick(props, fieldPropKeys);
    const checkboxProps = omit(props, fieldPropKeys);

    const id = checkboxProps.id || `checkbox-${uniqueId()}`;
    const Element = checkboxProps.component || Checkbox;

    const name = fieldProps.name || "";

    return (
        <Field {...fieldProps}>
            <Element {...checkboxProps} id={id} checked={fieldProps.formik?.values[name]} onChange={(checked: any) => {
                fieldProps.formik?.setFieldValue(name, checked);
                fieldProps.formik?.setFieldTouched(name);
            }}/>
        </Field>
    )
}

export {CheckboxField};
