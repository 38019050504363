import {FormControl, FormControlProps} from 'react-bootstrap';
import clsx, {ClassValue} from "clsx";
import React, {forwardRef, HTMLInputTypeAttribute, useCallback, useMemo, useState} from "react";
import {BsEye, BsEyeSlash, BsX} from "react-icons/bs";
import {Button} from "@etk/droplo-components";
import styles from './Input.module.scss';

export type AllowedTypes = Omit<HTMLInputTypeAttribute, "button" | "checkbox" | "radio" | "image" | "file" | "hidden" | "range" | "reset" | "submit">;

export interface InputProps extends Omit<FormControlProps, "type"> {
    /** Setting autofocus on input */
    autoFocus?: boolean,
    /** Solid style */
    solid?: boolean,
    /** White style */
    white?: boolean,
    /** Floating label type */
    floating?: boolean,
    /** Label when using floating prop */
    label?: string,
    /** Input type */
    type?: AllowedTypes,
    /** Show button which clears input value on click */
    clearable?: boolean,
    /** Icon to display around input */
    icon?: React.ReactNode,
    /** Icon position */
    iconPosition?: "left" | "right",
    /** Additional icon classes */
    iconClassName?: ClassValue | ClassValue[],
    /** Icon click callback */
    onIconClick?: () => void,
    /** Callback called when the value is cleared by click the clear button */
    onClear?: () => void
}

interface FloatingLabelProps {
    label: string,
    children: React.ReactNode
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
    const {
        autoFocus = false,
        solid = true,
        white = false,
        floating = false,
        label = "",
        icon = "",
        iconPosition = "left",
        iconClassName = "",
        type = "text",
        onChange = () => {
        },
        onIconClick = () => {
        },
        clearable = true,
        onClear = () => {
        },
        ...baseProps
    } = props;

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const input = (
        <FormControl
            data-ctx={'input'}
            autoFocus={autoFocus}
            {...baseProps}
            onChange={onChange}
            ref={ref}
            placeholder={baseProps.placeholder || (floating ? label : '')}
            className={clsx([
                baseProps.className,
                styles["form-control"],
                white && 'form-control-white',
                solid && !white && 'form-control-solid',
                icon && iconPosition === "left" && "ps-11",
                ((icon && iconPosition === "right") || type === "password") && "pe-13",
                !props.plaintext && [
                    props.isInvalid && "border-danger",
                    props.isValid && "border-success"
                ]
            ])} type={(type === "password" ? (isPasswordVisible ? "text" : "password") : type) as string}/>
    );

    const FloatingLabelContainer = useCallback(({label, children}: FloatingLabelProps) => {
        return (
            <div className={"form-floating"}>
                {children} <label>{label}</label>
            </div>
        )
    }, []);

    const hasValue = useMemo(() => {
        switch (typeof props.value) {
            case 'string':
                return !!props.value;
            case 'number':
                return true;
            case 'object':
                return !!props.value.toString();
            default:
                return false;
        }
    }, [props.value])

    const IconContainer = useCallback(({children}: {children: any}) => (
        !floating ? <div className="position-relative flex-grow-1">{children}</div> : <>{children}</>
    ), [floating]);

    const rightBtnClass = "fs-3 p-0 lh-1";

    const wrappedInput = (type === "password" || icon || clearable) ? (
        <IconContainer>
            {icon && iconPosition === "left" && (
                <span onClick={onIconClick}
                      className={clsx("position-absolute translate-middle-y top-50 ms-3 fs-3", iconClassName)}>
                    {icon}
                </span>
            )}
            {input}
            <div
                className={clsx("position-absolute translate-middle-y top-50 end-0 d-flex gap-3", (!baseProps.plaintext && (baseProps.isInvalid || baseProps.isValid)) ? "me-13" : "me-4")}>
                {clearable && hasValue && (
                    <Button link className={clsx(rightBtnClass, 'text-dark')}
                            onClick={() => {
                                onClear();
                                const changeEvent = {
                                    target: {
                                        value: ''
                                    }
                                } as any;
                                onChange(changeEvent);
                            }}
                    >
                        <BsX/>
                    </Button>
                )}
                {type === "password" && (
                    <Button link className={clsx(rightBtnClass, 'text-dark')}
                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                        {isPasswordVisible ? <BsEyeSlash/> : <BsEye/>}
                    </Button>
                )}
                {icon && iconPosition === "right" && (
                    <span onClick={onIconClick} className={clsx(rightBtnClass, iconClassName)}>
                        {icon}
                    </span>
                )}
            </div>
        </IconContainer>
    ) : input;

    return floating
        ? (
            <FloatingLabelContainer label={label}>
                {wrappedInput}
            </FloatingLabelContainer>
        )
        : wrappedInput
});
