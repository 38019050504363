import {FormikProps} from "formik/dist/types";
import {get} from "lodash";


interface ResponseErrorInterface {
    statusCode: number
    statusMessage?: string | string[]
    message?: string | string[]
}

export type ServerErrors = Record<string, string>;


export const fieldError = <T = any>(formik?: FormikProps<T>, name?: string, errors?: ServerErrors): string => {
	let error = "";
	if (name) {
        if (formik) {
            const isTouched = get(formik.touched, name, false);
            if (isTouched) {
                error = get(formik.errors, name, "") as string;
            }
        }
        if (!error && typeof errors !== "undefined") {
            error = get(errors, name, "");
        }
	}
	return error;
}

export const formikBlur = <Values = any>(formik: FormikProps<Values>, name: string) => {
    return formik.handleBlur({target: {name: name}})
}

export const formikChange = <Values = any>(formik: FormikProps<Values> , name: string, option: Object | string | number | undefined) => {
    formik.setFieldValue(name, option);
}
