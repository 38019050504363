import React, {FC } from 'react';
import { Input, InputProps } from '../Input';
import { Field, fieldPropKeys, FieldProps } from '../Field';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { fieldError } from '../../utils/formik/FormikHelpers';
import { InputGroup } from 'react-bootstrap';
import clsx from 'clsx';

type SchemaColors = 'secondary' | 'light' | 'dark' | 'gray' | 'transparent'

export type InputFieldProps = InputProps & FieldProps & {
    leftAddon?: string | JSX.Element,
    rightAddon?: string | JSX.Element,
    leftaddonbg?: SchemaColors,
    rightaddongb?: SchemaColors,
    fieldClassName?: string,
    input?: JSX.Element,
};

export const InputField: FC<InputFieldProps> = ({ fieldClassName, input, ...props }) => {
    const solid = typeof props.solid !== 'undefined' ? props.solid : true;
    const {
        leftAddon,
        rightAddon,
        leftaddonbg = 'transparent',
        rightaddongb = 'transparent'
    } = pick(props, ['leftAddon', 'rightAddon', 'leftaddonbg', 'rightaddonbg']);
    const fieldProps = pick(props, fieldPropKeys);
    const inputProps = omit(props, fieldPropKeys.concat('leftAddon', 'rightAddon'));
    const error = fieldError(props.formik, props.name);

    fieldProps.className = fieldClassName;

    if (inputProps.floating) {
        inputProps.label = fieldProps.label;
        delete fieldProps.label;
    }

    if (!input) {
        input = <Input isInvalid={!!error} {...props.formik?.getFieldProps(props.name as any)} {...inputProps} />;
    }

    return (
        <Field {...fieldProps}>
            {(leftAddon || rightAddon)
                ? <InputGroup className={clsx(solid && 'input-group-solid flex-nowrap')}>
                    {leftAddon && <InputGroup.Text className={`bg-${leftaddonbg} flex-shrink-0`}>{leftAddon}</InputGroup.Text>}
                    {input}
                    {rightAddon && <InputGroup.Text className={`bg-${rightaddongb} flex-shrink-0`}>{rightAddon}</InputGroup.Text>}
                </InputGroup>
                : input}
        </Field>
    )
}
