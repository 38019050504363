import React, {useMemo, useState} from 'react';
import {Alert, Button, Card, Col, Container, Row} from "@etk/droplo-components";
import {TypeBox} from "./components/TypeBox";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {InputField} from "./components/InputField";
import {CheckboxField} from "./components/CheckboxField";
import clsx from "clsx";
import {Checkbox} from "./components/Checkbox";
import {BsExclamationTriangle} from "react-icons/bs";

type UserType = 'retailer' | 'supplier';

interface FormData {
    name: string,
    company_name: string,
    website: string,
    email: string,
    phone: string,
    type: UserType,
    newsletter_consent: boolean
}

interface UserTypeDef {
    type: UserType,
    title: string,
    description?: string
}

const types: UserTypeDef[] = [{
    type: 'retailer',
    title: 'Sprzedawca',
    description: 'Szukam produktów do dalszej odsprzedaży'
}, {
    type: 'supplier',
    title: 'Dostawca',
    description: 'Mam produkty, które chciałbym sprzedawać za pośrednictwem Droplo'
}]

const INITIAL_VALUES = {
    "name": undefined,
    "company_name": undefined,
    "website": undefined,
    "email": undefined,
    "phone": undefined,
    "type": undefined,
    "newsletter_consent": false
};

const messages = {
    required: 'To pole jest wymagane',
    email: 'Podaj prawidłowy adres e-mail'
}

const fetchUrl = process.env.REACT_APP_API_URL as string;

function App() {
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [isGdprAccepted, setIsGdprAccepted] = useState(false);
    const [hasError, setHasError] = useState(false);

    const formik = useFormik<Partial<FormData>>({
        initialValues: INITIAL_VALUES as any,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(messages.required),
            company_name: Yup.string(),
            website: Yup.string(),
            email: Yup.string().required(messages.required).email(messages.email),
            phone: Yup.string().required(messages.required),
            type: Yup.string().required(messages.required),
            newsletter_consent: Yup.boolean()
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);

            try {
                const response = await fetch(`${fetchUrl}/leads`, {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        name: values.name,
                        company_name: values.company_name,
                        website: values.website,
                        email: values.email,
                        phone: values.phone,
                        // @ts-ignore
                        type: values.type,
                        newsletter_consent: values.newsletter_consent
                    })
                })
                if (response.ok) {
                    setIsSent(true)
                } else {
                    setHasError(true);
                }
            } catch(error) {
                setHasError(true);
            } finally {
                setIsSubmitting(false);
            }
        }
    })

    const selectedType = useMemo(() => {
        return types.find(({type}) => type === formik.values.type)
    }, [formik.values])

    return (<div className={'py-10 min-vh-100 d-flex flex-column justify-content-center align-items-center'}>
        <Container>
            <div className={"text-center mb-10"}>
                <img alt="droplo logo" src={'/logo.png'} />
            </div>
            <Card className={"mx-auto w-100 w-lg-700px"}>
                <Card.Body className={"px-20 py-11"}>
                    <div className={"px-5 text-center"}>
                        {!isSent
                            ? <>
                                {step === 1 && (<>
                                    <h1 className={"fs-3x lh-1"}>Określ swoją rolę</h1>
                                    <p className={"fs-1 text-gray-400 fw-bold mb-10"}>Wybierz rodzaj</p>
                                    <Row className={"row-cols-1 row-cols-md-2"}>
                                        {types.map(({title, type, description}, index) => (
                                            <Col key={index} className={clsx(index === 1 && "mt-5 mt-md-0")}>
                                                <TypeBox className={"h-100"} onClick={() => {
                                                    formik.setFieldValue('type', type);
                                                    setStep(2);
                                                }} title={title} active={formik.values.type === type}
                                                         description={description}/>
                                            </Col>
                                        ))}
                                    </Row>
                                </>)}
                                {step === 2 && (<div className={"text-start"}>
                                    <div className="d-flex justify-content-end">
                                        <span onClick={() => setStep(1)}
                                            className={"fs-4 fw-bold bg-light-primary border border-primary border-dashed py-2 px-10 rounded-2 mb-5"}>{selectedType?.title}</span>
                                    </div>
                                    {hasError && <Alert variant={"danger"} light borderDashed>
                                        <div className="d-flex align-items-center gap-3">
                                            <BsExclamationTriangle className={"fs-4"} />
                                            <span>Wystąpił nieoczekiwany błąd podczas zapisu.</span>
                                        </div>
                                    </Alert>}
                                    <InputField labelColProps={{className: "fs-4"}} className={"py-4 px-7"}
                                                clearable={false} placeholder={"Wpisz tutaj"} label={"Imię"} required
                                                formik={formik} name={'name'}/>
                                    <InputField labelColProps={{className: "fs-4"}} className={"py-4 px-7"}
                                                clearable={false} placeholder={"Wpisz tutaj"} label={"Adres E-mail"}
                                                required formik={formik} type={'email'} name={'email'}/>
                                    <InputField labelColProps={{className: "fs-4"}} className={"py-4 px-7"}
                                                clearable={false} placeholder={"Wpisz tutaj"}
                                                label={"Telefon kontaktowy"} required formik={formik}
                                                inputMode={'numeric'} name={'phone'}/>
                                    <Row>
                                        <Col>
                                            <InputField labelColProps={{className: "fs-4"}} className={"py-4 px-7"}
                                                clearable={false} placeholder={"Wpisz tutaj"} label={"Nazwa firmy"}
                                                formik={formik} name={'company_name'}/>
                                        </Col>
                                        <Col>
                                            <InputField labelColProps={{className: "fs-4"}} className={"py-4 px-7"}
                                                clearable={false} placeholder={"Wpisz tutaj"} label={"Adres URL"}
                                                formik={formik} name={'website'}/>
                                        </Col>
                                    </Row>
                                    <CheckboxField solid={false} className={"mt-5"} fieldClassName={"fs-7 lh-sm"}
                                                   inputClassName={"pe-3"} formik={formik} name={"newsletter_consent"}>
                                        Chcę otrzymywać newsletter Droplo z najlepszymi produktami do sprzedaży. Rozumiem, że w każdej chwili mogę się z niego wypisać.
                                    </CheckboxField>
                                    <Row className={"fs-7 lh-sm"}>
                                        <Col xs={12}>
                                            <Checkbox checked={isGdprAccepted} solid={false} onChange={setIsGdprAccepted}
                                                className={"mt-3 mb-6"} inputClassName={"pe-3"}>
                                                Potwierdzam, że zapoznałem się z regulaminem i polityką prywatności Droplo oraz&nbsp;akceptuję ich warunki.<span className={"text-danger"}>*</span>
                                            </Checkbox>
                                        </Col>
                                    </Row>

                                    <Button disabled={!isGdprAccepted || isSubmitting} loading={isSubmitting} className={"fs-3 fw-bolder w-100 py-3"}
                                            onClick={formik.submitForm}>Dalej</Button>
                                </div>)}
                            </>
                            : <div>
                                <div className="py-5 d-flex align-items-center flex-column justify-content-center">
                                    <img alt={"success image"} src={"/ok.png"}/>
                                    <h1 className={'mt-10 fs-2x'}>Dziękujemy za rozmowę!</h1>
                                    <p className={"fs-4 text-muted"}>Podejmiemy kontakt mailowy w najbliższych
                                        dniach.</p>
                                    <Button onClick={() => {
                                        setIsSent(false);
                                        setHasError(false);
                                        setIsSubmitting(false);
                                        setStep(1);
                                        setIsGdprAccepted(false);
                                        formik.resetForm();
                                    }} className={"mt-6 fs-3 fw-bolder"}>Ok, rozumiem!</Button>
                                </div>
                            </div>
                        }
                    </div>
                </Card.Body>
            </Card>
        </Container>
    </div>)
}

export default App;
