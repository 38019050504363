import React, { ReactNode } from "react";
import Form from "react-bootstrap/esm/Form";
import {Col, ColProps, Row} from "@etk/droplo-components";
// import {HelpHint} from "../HelpHint/HelpHint";
import {FormikProps} from "formik/dist/types";
import {fieldError, ServerErrors} from "../../utils/formik/FormikHelpers";
import clsx from "clsx";

export interface FieldInterface<Keys> {
    key: Keys,
    label?: string
    help?: string,
    type?: string,
    description?: string,
    col?: ColProps,
    validation?: any,
    readOnly?: boolean,
    component?: string,
    componentProps?: Record<string, any>,
    showIf?: (values: any) => boolean
}

export interface FieldProps<T = any> {
    label?: string,
    name?: string,
    formik?: FormikProps<T>,
    errors?: ServerErrors,
    horizontal?: boolean,
    required?: boolean,
    showErrorMessage?: boolean,
    labelColProps?: ColProps,
    inputColProps?: ColProps,
    help?: string,
    description?: string,
    fieldClassName?: string,
    children?: ReactNode,
}

export const fieldPropKeys = ["label", "name", "formik", "errors", "horizontal", "required", "labelColProps", "inputColProps", "help", "description", "fieldClassName", 'showErrorMessage'];

export const Field: React.FC<FieldProps> = (props) => {
    const {
        horizontal = false,
        labelColProps = 3,
        inputColProps = 9,
        label,
        formik,
        errors,
        name,
        help,
        required = false,
        showErrorMessage = true,
        description,
        children,
        fieldClassName
    } = props;

    const formLabelProps = !horizontal ? {...(typeof labelColProps === 'object' ? labelColProps : {}), xs: 12} : (typeof labelColProps === 'object' ? labelColProps : {xs: labelColProps});
    const inputContainerProps = !horizontal ? {xs: 12} : (typeof inputColProps === 'object' ? inputColProps : {xs: inputColProps});

    const error = fieldError(formik, name, errors);

    return <Form.Group as={Row} className={fieldClassName}>
        {label && (
            <Form.Label column {...formLabelProps}>
                {label.trim()}{required && <span className={"text-danger"}>*</span>}
                {/* {help && <HelpHint content={help}/>} */}
            </Form.Label>
        )}
        <Col {...inputContainerProps}>
            {children}
            {error && showErrorMessage &&
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' dangerouslySetInnerHTML={{__html: error}}/>
                    </div>
                </div>
            }
            {description && <Form.Text>{description}</Form.Text>}
        </Col>
    </Form.Group>
}
