import {FC, HTMLProps, useState} from "react";
import clsx from "clsx";

interface TypeBoxProps {
    title: string;
    description?: string;
    active?: boolean;
    onClick?: () => void
}

export const TypeBox: FC<TypeBoxProps & HTMLProps<HTMLDivElement>> = ({title, description, active = false, onClick = () => {}, ...divProps}) => {
    const [hover, setHover] = useState<boolean>(false);

    return (<div
        {...divProps}
        className={clsx([
            'card card-flush h-xl-100 cursor-pointer border border-1 border-dashed bg-gray-500 border-gray-400 bg-hover-light-primary border-hover-primary',
            active && "bg-light-primary bg-border-primary",
            divProps.className
        ])}
        onClick={onClick}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <div className="card-body flex-column flex-nowrap py-12">
            <h1 className={clsx("fs-2x flex-row text-center")}>
                <span className="card-label fw-bolder">
                    {title}
                </span>
            </h1>
            {!!description && <p className="mt-5 mb-0 text-muted">
                {description}
            </p>}
        </div>
    </div>)
}
